import React from 'react';
import './Footer.css';

class Footer extends React.Component {
  render() {
    return (
      <footer className="Footer">
        <a className="FooterLink" href="https://garakuta-toolbox.com" target="_blank" rel="noopener noreferrer">がらくたツールボックス</a>
      </footer>
    );
  }
}

export default Footer;
