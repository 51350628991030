import React from 'react';
import './DefaultTemplate.css';
import Footer from 'components/Footer';
import logo from 'assets/images/picopiyo-title.png';
import {
  Link
} from "react-router-dom";

export default class DefaultTemplate extends React.Component {
  render() {
    return (
      <div>
        <article className="Outer">
          { this.props.showAd &&
            <a href="https://dungeon.garakuta-toolbox.com" target="_blank" rel="noreferrer noopener">
              <div className="AdGarakutaToolbox">
                <p>2次元マップワールドシステム</p>
                <p>みんなでつくるダンジョン</p>
              </div>
            </a>
          }
          <div className="LogoOuter">
            <p>ドット絵ツイート支援サービス「ぴこぴよ」</p>
            <Link to="/"><img src={logo} alt="logo" /></Link>
          </div>
          {this.props.children}
        </article>
        <Footer />
      </div>
    );
  }
}
