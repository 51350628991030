import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import TopPage from './pages/TopPage';
import DefaultTemplate from './templates/DefaultTemplate'
import { PixelartElement } from '@piyoppi/pixelart-element';
import * as serviceWorker from './serviceWorker';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <DefaultTemplate showAd="true">
            <TopPage />
          </DefaultTemplate>
        </Route>
      </Switch>
    </Router>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

customElements.define('pixelart-img', PixelartElement);
