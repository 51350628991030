import React from 'react';
import './Alert.css';

export default class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.timerId = null;
  }

  resetTimer() {
    if( this.timerId ) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  setTimer() {
    this.timerId = setTimeout(() => {
      this.timerId = null;
      if( this.props.onElapsed ) this.props.onElapsed();
    }, parseInt(this.props.duration));
  }

  render() {
    let alertClasses = 'AlertOuter';

    if( !this.props.message ) {
      alertClasses += ' AlertHidden';
    }

    if( this.props.mode === 'notice' ) {
      alertClasses += ' AlertNotice';
    }

    this.resetTimer();

    if( this.props.duration ) {
      this.setTimer();
    }

    return (
      <div className="AlertContainer">
        <div className={alertClasses}>
          { this.props.message }
        </div>
      </div>
    );
  }
}
