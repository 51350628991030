import React from 'react';
import './TopPage.css';
import Alert from 'components/Alert';
import ArrangementExampleImage from 'assets/images/arrangement-example.png';

class TopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      alert: {
        message: ''
      }
    }
  }

  async login(e) {
    this.setState({loading: true});

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/twitter_request_token`, {method: 'POST'});
      if( response.status === 200 ) {
        const body = await response.json();
        window.location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${body.request_token}`;
      } else {
        this.setState({alert: {message: '処理に失敗しました'}});
        this.setState({loading: false});
      }
    } catch(e) {
      this.setState({alert: {message: '処理に失敗しました'}});
      this.setState({loading: false});
    }
  }

  render() {
    return (
      <div>
        <div className="LoginOuter">
          <p>ドット絵を拡大してツイッターに投稿できるサービスです</p>
          <div>
            <a href="https://garakuta-toolbox.com/weblog/goodbye-picopiyo/">【重要】ぴこぴよは終了しました。これまでご利用いただきありがとうございます。</a>
          </div>
        </div>
        <section className="Section">
          <h2>これはなに？</h2>
          <img src={ArrangementExampleImage} alt="" />
          <ul className="NoticeList">
            <li>お手持ちのドット絵を拡大してツイッターに投稿できます</li>
            <li>異なる倍率の絵を並べた画像をつくれます</li>
          </ul>
          <p><a href="https://twitter.com/hashtag/picopiyo">ツイート例はこちら</a></p>
        </section>
        <section className="Section">
          <h2>つかうときのルール</h2>
          <ul className="NoticeList">
            <li>
              このサービスによってユーザーのみなさまに損害が発生した場合でも、作者は一切の責任を負いません。
            </li>
            <li>
              短時間に多量の投稿をしないでください
            </li>
          </ul>
        </section>
        <section className="Section">
          <h2>作者情報</h2>
          <p>ぴよっぴ(<a href="https://twitter.com/piyorinpa">@piyorinpa</a>)がつくっています。</p>
        </section>
        <section>
          v{process.env.REACT_APP_VERSION}
        </section>
        <Alert message={this.state.alert.message} onElapsed={() => this.state.alert.message && this.setState({alert: {message: ''}})} duration="4000"/>
      </div>
    );
  }
}

export default TopPage;
